import styled from "@emotion/styled";
import { Button, Color, Typography } from "@ibeckinc/ui-elements";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { NextLink } from "../../../components/NextLink";
import { useMobile } from "../../../hooks/useMobile";
import { useResizeObserver } from "../../../hooks/useResizeObserver";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { pagesPath } from "../../../lib/$path";
import { device } from "../../../utils";
import HeroLogoIcon from "./icons/HeroLogoIcon";

const { Text, Title } = Typography;

const HERO_IMG_ASPECT_RATIO = 0.3;

const HeroArea: FunctionComponent = () => {
  const { width: windowWidth } = useWindowSize();
  const { isMobile } = useMobile();

  const [heroImgWidth, setHeroImgWidth] = useState(980);

  const element = useRef<HTMLDivElement>(null);
  const handleResize = useCallback(() => {
    windowWidth <= 1000
      ? setHeroImgWidth(980 - 183)
      : setHeroImgWidth(980 + (windowWidth - 1366) / 2);
  }, [windowWidth]);

  useResizeObserver([element], handleResize);

  useEffect(() => {
    setHeroImgWidth(document.documentElement.clientWidth);
  }, []);

  return (
    <Root ref={element}>
      <AchivementCopy heroAreaHeight={heroImgWidth * HERO_IMG_ASPECT_RATIO}>
        {isMobile() ? (
          <>
            <BrandColorTitle level={2} multiline alignment="center">
              7年連続日本1位！
            </BrandColorTitle>
            <BrandColorTitle level={2} multiline alignment="center">
              個人のキャリア最大化を支援
            </BrandColorTitle>
          </>
        ) : (
          <BrandColorTitle level={2}>
            7年連続日本1位！個人のキャリア最大化を支援
          </BrandColorTitle>
        )}
      </AchivementCopy>
      <LogoButtonArea>
        <LogoContainer>
          <LogoWithText>
            <HeroLogoIcon isMobile={isMobile()} color={Color.Text.BlueBlack} />
          </LogoWithText>
          <TextContainer>
            {isMobile() ? (
              <>
                <Text multiline variant="body">
                  オルタナティブキャリアは、
                </Text>
                <Text multiline variant="body">
                  個人の可能性最大化を実現するために、
                </Text>
                <Text multiline variant="body">
                  スキルアップや成長機会を支援しています。
                </Text>
              </>
            ) : (
              <Text multiline variant="body">
                オルタナティブキャリアは、個人の可能性最大化を実現するために、スキルアップや成長機会を支援しています。
              </Text>
            )}
          </TextContainer>
        </LogoContainer>
        <ButtonsArea>
          <NextLink href={pagesPath.sign_up.$url()}>
            <_Button>会員登録する</_Button>
          </NextLink>
          <NextLink
            href={pagesPath.sign_up.$url({
              query: { step: 1, userType: "job_seeker" },
            })}
          >
            <_Button variant="outline" color="secondary">
              中途の方はこちら
            </_Button>
          </NextLink>
        </ButtonsArea>
      </LogoButtonArea>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-top: -8px;

  @media ${device.pc} {
    min-width: 1000px;
    margin-top: -76px;
  }
`;

const AchivementCopy = styled.div<{ heroAreaHeight: number }>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 551px;
  padding-top: 4px;

  @media ${device.pc} {
    background: ${Color.Background.Base};
  }

  @media ${device.mobile} {
    width: 100%;
    left: 0;
  }
`;

const BrandColorTitle = styled(Title)`
  color: transparent;
  background: ${Color.Background.Brand};
  background-clip: text;
`;

const LogoButtonArea = styled.div`
  height: 208px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    flex-direction: column;
    height: 100%;
    padding: 0;
    margin-top: 16px;
  }
`;

const LogoContainer = styled.div`
  box-sizing: border-box;
  flex: 1;
`;

const LogoWithText = styled.div`
  display: flex;

  @media ${device.mobile} {
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  width: 496px;
  margin-top: 10px;

  @media ${device.mobile} {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
`;

const ButtonsArea = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;

  @media ${device.pc} {
    margin: 24px 0 0 0;
  }

  @media ${device.mobile} {
    flex-direction: column;
    padding: 24px 0;
    margin-top: 24px;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100vw;
      margin-top: -24px;
      background: ${Color.Background.LightBrand};
    }
  }
`;

const _Button = styled(Button)`
  width: 200px;
`;

export default HeroArea;
